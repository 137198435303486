import { Trans } from "@common/i18n/trans";
import { Button } from "@common/ui/buttons/button";
import { Form } from "@common/ui/forms/form";
import { FormTextField } from "@common/ui/forms/input-field/text-field/text-field";
import { Dialog } from "@common/ui/overlays/dialog/dialog";
import { DialogBody } from "@common/ui/overlays/dialog/dialog-body";
import { useDialogContext } from "@common/ui/overlays/dialog/dialog-context";
import { DialogFooter } from "@common/ui/overlays/dialog/dialog-footer";
import { DialogHeader } from "@common/ui/overlays/dialog/dialog-header";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Workspace } from "./types/workspace";
import { useVerifyPin } from "./requests/verify-pin";

export interface EnterPinPayload {
  id: number;
  pin: string;
}
interface Props {
  workspace: Workspace;
  changeWorkspace: () => void;
  setWorkspacePin: (pin: string) => void;
} 

export function EnterPinDialog(props: Props) {
  const {workspace, changeWorkspace, setWorkspacePin} = props;
  const [pinValue, setPinValue] = useState("");
  const form = useForm<EnterPinPayload>({
    defaultValues: { id: workspace.id, pin: '' },
  });
  const verifyPin = useVerifyPin(form, changeWorkspace, setWorkspacePin);
  const {formId, close} = useDialogContext();

  const handleEnterPin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    // Regular expression to allow only digits
    if (/^\d*$/.test(newValue)) {
      setPinValue(newValue);
    }
  };

  const handleSubmit = () => {
    if (pinValue.length === 4) {
      const values = { id: form.getValues().id, pin: pinValue }
      verifyPin.mutate(values)
    } else {
      form.setError("pin", { type: 'min', message: 'Enter 4 digits for PIN' })
    }
  }

  return (
    <Dialog>
      <DialogHeader>
        <Trans message="Enter PIN" />
      </DialogHeader>
      <DialogBody>
        <Form
          form={form}
          id={formId}
          onSubmit={handleSubmit}
        >
          <FormTextField
            name="pin"
            autoFocus
            maxLength={4}
            required
            value={pinValue}
            onChange={handleEnterPin}
            inputMode='numeric'
            type='password'
          />
        </Form>
      </DialogBody>
      <DialogFooter>
        <Button variant="text" onClick={close}>
          <Trans message="Cancel" />
        </Button>
        <Button
          variant="flat"
          color="primary"
          type="submit"
          form={formId}
        >
          <Trans message="Enter PIN" />
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
