import {useMutation} from '@tanstack/react-query';
import {UseFormReturn} from 'react-hook-form';
import {BackendResponse} from '../../http/backend-response/backend-response';
import {toast} from '../../ui/toast/toast';
import {apiClient, queryClient} from '../../http/query-client';
import {WorkspaceQueryKeys} from './workspace-query-keys';
import {Workspace} from '../types/workspace';
import {onFormQueryError} from '../../errors/on-form-query-error';
import {useDialogContext} from '../../ui/overlays/dialog/dialog-context';
import {message} from '../../i18n/message';

interface Response extends BackendResponse {
  workspace: Workspace;
}

export interface VerifyPinPayload {
  id: number;
  pin: string;
}

function verifyPinWorkspace({
  id,
  ...props
}: VerifyPinPayload): Promise<Response> {
  return apiClient.post(`workspace/${id}/pin-verify`, props).then(r => r.data);
}

export function useVerifyPin(
  form: UseFormReturn<VerifyPinPayload>,
  changeWorkspace: () => void,
  setWorkspacePin: (pin: string) => void
) {
  const {close} = useDialogContext();
  return useMutation({
    mutationFn: (props: VerifyPinPayload) => verifyPinWorkspace(props),
    onSuccess: _response => {
      setWorkspacePin(form.getValues().pin);
      changeWorkspace();
      close();
      queryClient.invalidateQueries({
        queryKey: WorkspaceQueryKeys.fetchUserWorkspaces,
      });
      queryClient.invalidateQueries({
        queryKey: WorkspaceQueryKeys.workspaceWithMembers(
          form.getValues().id,
        ),
      });
    },
    onError: r => onFormQueryError(r, form),
  });
}
